@import '@aws-amplify/ui-react/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

:root {
  --amplify-fonts-default-variable: 'Roboto';
  --amplify-fonts-default-static: 'Roboto';
  --amplify-colors-brand-primary-10: #ed82b4;
  --amplify-colors-brand-primary-20: #eb72ab;
  --amplify-colors-brand-primary-40: #e963a1;
  --amplify-colors-brand-primary-60: #e65398;
  --amplify-colors-brand-primary-80: #e4448f;
  --amplify-colors-brand-primary-90: #e23485;
  --amplify-colors-brand-primary-100: #e0257b;
}
