body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.hover-table-row .hover-table-cell .show-on-hover {
  display: none;
}

.hover-table-row:hover .hover-table-cell .show-on-hover {
  display: block;
}

.hover-table-row .hover-table-cell .hide-on-hover {
  display: block;
}

.hover-table-row:hover .hover-table-cell .hide-on-hover {
  display: none;
}

.and-line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}
.and-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.and-wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}

.and-word {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font:
    bold 12px arial,
    sans-serif;
  background: #fff;
}

.chapter-menu-list .MuiTableRow-root button.chapterMoreMenu {
  display: none;
}

.chapter-menu-list .MuiTableRow-root:hover button.chapterMoreMenu {
  display: flex;
}

.chapter-menu-list .MuiTableRow-root .chapter-menu-item-drag-icon {
  display: none;
}

.chapter-menu-list .MuiTableRow-root:hover .chapter-menu-item-drag-icon {
  display: block;
}

.chapter-menu-list .dragging .chapter-menu-item-drag-icon {
  display: block;
}

.chapter-menu-list .MuiTableRow-root .chapter-menu-item-counter {
  display: block;
}

.chapter-menu-list .MuiTableRow-root:hover .chapter-menu-item-counter {
  display: none;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@property --powerBIcol1 {
  syntax: '<color>';
  initial-value: #ffe375;
  inherits: false;
}

@property --powerBIcol2 {
  syntax: '<color>';
  initial-value: #ffd741;
  inherits: false;
}
@property --powerBIcol3 {
  syntax: '<color>';
  initial-value: #ffd53c;
  inherits: false;
}
@property --powerBIcol4 {
  syntax: '<color>';
  initial-value: #f9b100;
  inherits: false;
}
@property --powerBIcol5 {
  syntax: '<color>';
  initial-value: #f6a900;
  inherits: false;
}
@property --powerBIcol6 {
  syntax: '<color>';
  initial-value: #e08200;
  inherits: false;
}
